/* The following block can be included in a custom.scss */

/* make the customizations */
$theme-colors: (
    "primary": #4099de,
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
// @import "./features/espp/react-datepicker.css";
// @import "react-datepicker/dist/react-datepicker";
// @import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';



svg { vertical-align: baseline; }
